<template>  
  <b-card v-if="isloading">
    <center><b-spinner></b-spinner></center>
  </b-card>
  <b-card v-else>
    <b-card-title>
      <div class="d-flex w-100"><h5 class="mr-auto">{{actualcow}}</h5><h5 class="">{{actualcatnr}}</h5></div>
    </b-card-title>
    <b-card-text class="mt-2 pt-2 w-100 border-top">
      <div class="d-flex justify-content-between">
        <div class="w-40">
          <div class="font-small">Status:</div>
          <h4 class="text-center text-primary height-1 align-middle d-flex align-items-center">
            <div v-html="auctionatorvalue"></div>
          </h4>
        </div>
        <div class="w-50">
          <div class="text-right w-100 font-small">Aktuelles Gebot:</div>
          <div class="height-1 d-flex align-items-center">

            <div class="w-100">
              <h3 class="text-right text-primary mt-3 mb-0">{{actualprice}}.- SFr.</h3>
              <div class="text-right w-100 font-small">{{actualbuyer}}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="newgebot" class="anfrage">
      Onlinebieter:<br>
      <h1>Neues Gebot: {{anfrage}}.- SFr.</h1>
      
    </div>
    </b-card-text>
    
  </b-card>
</template>

<script>
export default {
  name: 'Cow-Card',
  data(){
    return {
      selected: 'C',
      isloading: false,
      connection: null,
      auktionvalue: 1
    }
  },
  props: {
    live: Boolean,
  },
  components: {},
  mounted(){
    this.isloading = true
      if (this.$store.state.socket.isConnected){
        var adduser = {
          event: 'add saal',
        }
        this.$socket.sendObj(adduser);
      }
      this.$options.sockets.onopen = (data) => {
        console.log(data);
          var adduser = {
            event: 'add saal',
          }
          this.$socket.sendObj(adduser);
        }
      this.$options.sockets.onmessage = (data) => {
          var resp = JSON.parse(data.data);
          console.log(resp);
          if (resp.event == 'actualgebot'){
            this.isloading = false
            var jsongebot = resp.gebot;
            if (this.live){
              if (jsongebot.saleuser != null){
                if (jsongebot.auktionvalue == 4 && jsongebot.saleuser.email == this.$store.getters.getUser.email && jsongebot.saleuser.email != undefined){
                  this.showWin();
                }
              }
            }
            this.auktionvalue = jsongebot.auktionvalue;
            this.$store.commit('SET_GEBOT',jsongebot);
            console.log(jsongebot);
          }
        }
    window.scrollTo(0, 0)
  },
  // sockets: {
  //   actualprice(gebot) {

  //       this.isloading = false
  //       var jsongebot = JSON.parse(gebot);
  //       if (this.live){
  //         if (jsongebot.auktionvalue == 4 && jsongebot.saleuser.email == this.$store.getters.getUser.email && jsongebot.saleuser.email != undefined){
  //           this.showWin();
  //         }
  //       }
  //       this.$store.commit('SET_GEBOT',jsongebot);
  //   }
  // },
  methods: {
    closemodal(){
        this.$refs.win.hide();
    },
    bieten(){
      var gebot = {
        user: this.$store.getters.getUser,
        price: this.actualgebot,
        event: 'set newgebot'
      }
      this.$socket.sendObj(gebot);
      
      // this.$socket.emit('new gebot',this.actualgebot,this.$store.getters.getUser)
    },
    showWin() {
        this.$refs.win.show();
    },
    setTransport(){

    }
  },
  computed:{
    newgebot(){
      if (this.$store.state.gebot.anfrage > 0){
        return true;
      } else {
        return false;
      }
    },
    isloggedin(){
      return this.$store.getters.isLoggedIn
    },
    actualcatnr(){
      return 'Kat.-Nr. '+this.$store.state.gebot.katnr
    },
    actualcow(){
      return this.$store.state.gebot.tiername
    },
    actualgebot(){
        return this.$store.state.gebot.new
    },
    actualprice(){
      return this.$store.state.gebot.act
    },
    anfrage(){
      return this.$store.state.gebot.anfrage
    },
    actualbuyer(){
      if (this.$store.state.gebot.saleuser != null){
        if (this.$store.state.gebot.saleuser.email!='' && this.$store.state.gebot.saleuser.email!=undefined)
        return 'Onlinebieter'
        return 'Bieter im Saal'
      }
      return 'Bieter im Saal'
    },
    auctionatorvalue(){
        if (this.auktionvalue === 0) return 'Auktion noch <br> nicht offen';
        if (this.auktionvalue === 1) return "Auktion<br>offen";
        if (this.auktionvalue === 2) return 'Zum<br>1.';
        if (this.auktionvalue === 3) return 'Zum<br>2.';
        if (this.auktionvalue === 4) return 'Zum 3.<br>Verkauft';
        return '';
      
    },
    isonline(){
      if (!this.online){
        return 'isoffline'
      }
      return ''
    }
  }
  
}
</script>

<style style="scss" scoped>
.cardsize  {
  height: 150px;
}
.right-0{
  right: 0%;
}
.bottom-0{
  bottom: 0%;
}
.w-40 {
  width: 40%;
}
.height-1{
  height: 100px;
}
.font-small {
  font-size: 8pt;
}
.live {
  color: #2020ff;
}
.isoffline {
  background-color: #e0e0e0;
}
.anfrage{
  background-color: #2d5c53;
  height: 20%;
  font-size: 40px;
  color: white;
  text-align: center;
}
</style>
